export default [
  /*****************RUTAS MELA-BEAUTY SALES */
  {
    path: "/apps/sales/create",
    name: "apps-sales-create",
    component: () => import("@/views/apps/sales/FormWizard.vue"),
    meta: {
      pageTitle: "Sales",
      breadcrumb: [
        {
          text: "Sales",
        },
        {
          text: "Create",
        },
      ],
    },
  },
];
