export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () =>
      import("@/views/apps/customers/country-list/CountryList.vue"),
    meta: {
      pageTitle: "Users",
      breadcrumb: [
        {
          text: "Users",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/users/view/:id",
    name: "apps-users-view",
    component: () => import("@/views/apps/user/users-view/UsersView.vue"),
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },

  // Invoice
  {
    path: "/apps/invoice/list",
    name: "apps-invoice-list",
    meta: {
      redirectIfLoggedIn: true,
    },
    component: () =>
      import("@/views/apps/invoice/invoice-list/InvoiceList.vue"),
  },
  {
    path: "/apps/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
  },
  {
    path: "/apps/invoice/add/",
    name: "apps-invoice-add",
    component: () => import("@/views/apps/invoice/invoice-add/InvoiceAdd.vue"),
  },
  {
    path: "/apps/invoice/edit/:id",
    name: "apps-invoice-edit",
    component: () =>
      import("@/views/apps/invoice/invoice-edit/InvoiceEdit.vue"),
  },

  // Payroll
  {
    path: "/apps/payroll/payments",
    name: "apps-payroll-payments",
    component: () => import("@/views/apps/payroll/payments/Payments.vue"),
    meta: {
      pageTitle: "Payroll Payments",
      breadcrumb: [
        {
          text: "Payroll",
        },
        {
          text: "Payments",
        },
        {
          text: "List",
          active: true,
        },
      ],
    }
  },
  {
    path: "/apps/payroll/import/contractors",
    name: "apps-payroll-contractors-gusto",
    component: () => import("@/views/apps/payroll/contractors-upload-csv/Upload.vue"),
    meta: {
      pageTitle: "Sync Contractors From Gusto",
      breadcrumb: [
        {
          text: "Payroll",
        },
        {
          text: "Contractors",
        },
        {
          text: "Sync",
          active: true,
        },
      ],
    }
  },


  /*********RUTAS MELA-BEAUTY */
  {
    path: "/apps/country/list",
    name: "apps-country-list",
    component: () =>
      import("@/views/apps/country/country-list/CountryList.vue"),
    meta: {
      pageTitle: "Country",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "Country",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/country/edit/:id",
    name: "apps-country-edit",
    component: () =>
      import("@/views/apps/country/country-edit/CountryEdit.vue"),
    meta: {
      pageTitle: "Country",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "Country",
          to: { name: "apps-country-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/country/add/",
    name: "apps-country-add",
    component: () => import("@/views/apps/country/country-add/CountryAdd.vue"),
    meta: {
      pageTitle: "Country",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "Country",
          to: { name: "apps-country-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY STATE */
  {
    path: "/apps/state/list",
    name: "apps-state-list",
    component: () => import("@/views/apps/state/country-list/CountryList.vue"),
    meta: {
      pageTitle: "State",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "State",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/state/edit/:id",
    name: "apps-state-edit",
    component: () => import("@/views/apps/state/country-edit/CountryEdit.vue"),
    meta: {
      pageTitle: "State",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "State",
          to: { name: "apps-state-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/state/add/",
    name: "apps-state-add",
    component: () => import("@/views/apps/state/country-add/CountryAdd.vue"),
    meta: {
      pageTitle: "State",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "State",
          to: { name: "apps-state-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY CITY */
  {
    path: "/apps/city/list",
    name: "apps-city-list",
    component: () => import("@/views/apps/city/country-list/CountryList.vue"),
    meta: {
      pageTitle: "City",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "City",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/city/edit/:id",
    name: "apps-city-edit",
    component: () => import("@/views/apps/city/country-edit/CountryEdit.vue"),
    meta: {
      pageTitle: "City",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "City",
          to: { name: "apps-city-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/city/add/",
    name: "apps-city-add",
    component: () => import("@/views/apps/city/country-add/CountryAdd.vue"),
    meta: {
      pageTitle: "City",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "City",
          to: { name: "apps-city-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY EMPLOYEES */
  {
    path: "/apps/employee",
    name: "apps-employee-list",
    component: () =>
      import("@/views/apps/employee/employee-list/EmployeeList.vue"),
    meta: {
      pageTitle: "Employees",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Employee",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/employee/edit/:id",
    name: "apps-employee-edit",
    component: () =>
      import("@/views/apps/employee/employee-edit/EmployeeEdit.vue"),
    meta: {
      pageTitle: "Employee",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Employee",
          to: { name: "apps-employee-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/employee/add/",
    name: "apps-employee-add",
    component: () =>
      import("@/views/apps/employee/employee-add/EmployeeAdd.vue"),
    meta: {
      pageTitle: "Employee",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Employee",
          to: { name: "apps-employee-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/employee/view/:id",
    name: "apps-employee-view",
    component: () =>
      import("@/views/apps/employee/employee-view/EmployeeView.vue"),
    meta: {
      pageTitle: "Employee",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Employee",
          to: { name: "apps-employee-list" },
        },
        {
          text: "View",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/profile/view/",
    name: "apps-profile-view",
    component: () =>
      import("@/views/apps/profile/profile-view/EmployeeView.vue"),
    meta: {
      pageTitle: "Employee",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Employee",
          to: { name: "apps-employee-list" },
        },
        {
          text: "View",
          active: true,
        },
      ],
    },
  },
  /*****************RUTAS MELA-BEAUTY PRODUCTOS */
  {
    path: "/apps/product",
    name: "apps-product-list",
    component: () =>
      import("@/views/apps/product/product-list/ProductList.vue"),
    meta: {
      pageTitle: "Products",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Product",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/product/edit/:id",
    name: "apps-product-edit",
    component: () =>
      import("@/views/apps/product/product-edit/ProductEdit.vue"),
    meta: {
      pageTitle: "Products",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Product",
          to: { name: "apps-product-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/product/add/",
    name: "apps-product-add",
    component: () => import("@/views/apps/product/product-add/ProductAdd.vue"),
    meta: {
      pageTitle: "Products",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Product",
          to: { name: "apps-product-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY Services */
  {
    path: "/apps/service",
    name: "apps-service-list",
    component: () =>
      import("@/views/apps/service/service-list/ServiceList.vue"),
    meta: {
      pageTitle: "Service",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Service",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/service/edit/:id",
    name: "apps-service-edit",
    component: () =>
      import("@/views/apps/service/service-edit/ServiceEdit.vue"),
    meta: {
      pageTitle: "Service",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Service",
          to: { name: "apps-service-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/service/add/",
    name: "apps-service-add",
    component: () => import("@/views/apps/service/service-add/ServiceAdd.vue"),
    meta: {
      pageTitle: "Services",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Service",
          to: { name: "apps-service-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY OFFICE */
  {
    path: "/apps/office/list",
    name: "apps-office-list",
    component: () => import("@/views/apps/office/country-list/CountryList.vue"),
    meta: {
      pageTitle: "Office",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "Office",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/office/edit/:id",
    name: "apps-office-edit",
    component: () => import("@/views/apps/office/country-edit/CountryEdit.vue"),
    meta: {
      pageTitle: "Office",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "Office",
          to: { name: "apps-office-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/office/add/",
    name: "apps-office-add",
    component: () => import("@/views/apps/office/country-add/CountryAdd.vue"),
    meta: {
      pageTitle: "Office",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "Office",
          to: { name: "apps-office-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/office/detail/:id",
    name: "apps-office-detail",
    component: () => import("@/views/apps/office/country-detail/Detail.vue"),
    meta: {
      pageTitle: "Office",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Location",
        },
        {
          text: "Office",
          to: { name: "apps-office-list" },
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY CUSTOMERS */
  {
    path: "/apps/customers/list",
    name: "apps-customers-list",
    component: () =>
      import("@/views/apps/customers/country-list/CountryList.vue"),
    meta: {
      pageTitle: "Customers",
      breadcrumb: [
        {
          text: "Settings",
        },

        {
          text: "Customers",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/customers/edit/:id",
    name: "apps-customers-edit",
    component: () =>
      import("@/views/apps/customers/country-edit/CountryEdit.vue"),
    meta: {
      pageTitle: "Customers",
      breadcrumb: [
        {
          text: "Settings",
        },

        {
          text: "Customers",
          to: { name: "apps-customers-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/customers/add/",
    name: "apps-customers-add",
    component: () =>
      import("@/views/apps/customers/country-add/CountryAdd.vue"),
    meta: {
      pageTitle: "Customers",
      breadcrumb: [
        {
          text: "Settings",
        },

        {
          text: "Customers",
          to: { name: "apps-customers-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/customers/detail/:id",
    name: "apps-customers-detail",
    component: () => import("@/views/apps/customers/country-detail/Detail.vue"),
    meta: {
      pageTitle: "Customer",
      breadcrumb: [
        {
          text: "Settings",
        },

        {
          text: "Customers",
          to: { name: "apps-customers-list" },
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY ROLS */
  {
    path: "/apps/rols/list",
    name: "apps-rols-list",
    component: () => import("@/views/apps/rols/country-list/CountryList.vue"),
    meta: {
      pageTitle: "Roles",
      breadcrumb: [
        {
          text: "Settings",
        },

        {
          text: "Rol",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },

  {
    path: "/apps/rols/edit/:id",
    name: "apps-rols-edit",
    component: () => import("@/views/apps/rols/country-edit/CountryEdit.vue"),
    meta: {
      pageTitle: "Roles",
      breadcrumb: [
        {
          text: "Settings",
        },

        {
          text: "Rols",
          to: { name: "apps-rols-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/rols/add/",
    name: "apps-rols-add",
    component: () => import("@/views/apps/rols/country-add/CountryAdd.vue"),
    meta: {
      pageTitle: "Roles",
      breadcrumb: [
        {
          text: "Settings",
        },

        {
          text: "Roles",
          to: { name: "apps-rols-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  /*****************RUTAS MELA-BEAUTY Category */
  {
    path: "/apps/category/list",
    name: "apps-category-list",
    component: () =>
      import("@/views/apps/category/category-list/CountryList.vue"),
    meta: {
      pageTitle: "Categories",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Category",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/category/edit/:id",
    name: "apps-category-edit",
    component: () =>
      import("@/views/apps/category/category-edit/CountryEdit.vue"),
    meta: {
      pageTitle: "Categories",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Category",
          to: { name: "apps-category-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/category/add/",
    name: "apps-category-add",
    component: () =>
      import("@/views/apps/category/category-add/CountryAdd.vue"),
    meta: {
      pageTitle: "Categories",
      breadcrumb: [
        {
          text: "Settings",
        },
        {
          text: "Inventory",
        },
        {
          text: "Category",
          to: { name: "apps-category-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- Reports ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/reports/sales",
    name: "apps-reports-detail",
    component: () => import("@/views/apps/reports/sales-detail/Detail.vue"),
    meta: {
      pageTitle: "Reports",
      breadcrumb: [
        {
          text: "Reports",
        },
        {
          text: "Sales",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/reports/client-offices",
    name: "apps-client-detail",
    component: () => import("@/views/apps/reports/customer-detail/Detail.vue"),
    meta: {
      pageTitle: "Reports",
      breadcrumb: [
        {
          text: "Reports",
        },
        {
          text: "Client Offices",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/reports/payments-employees",
    name: "apps-payment-employee",
    component: () => import("@/views/apps/reports/payment-employee/Detail.vue"),
    meta: {
      pageTitle: "Reports",
      breadcrumb: [
        {
          text: "Reports",
        },
        {
          text: "Payments Employees",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },

  /* {
    path: '/apps/country/list',
    name: 'apps-country-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Countries',
      breadcrumb: [
        {
          text: 'Location',
        },
        {
          text: 'Countries',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/state/list',
    name: 'apps-state-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'State',
      breadcrumb: [
        {
          text: 'Location',
        },
        {
          text: 'State',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/city/list',
    name: 'apps-city-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Cities',
      breadcrumb: [
        {
          text: 'Location',
        },
        {
          text: 'Cities',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/employee/list',
    name: 'apps-employee-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Employees',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Employeees',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  }, */
];
