import Vue from "vue";

// axios
import axios from "axios";

let apiUrl;

switch (process.env.VUE_APP_SERVER) {
  case "LOCAL":
    apiUrl = process.env.VUE_APP_API_LOCAL;
    break;
  case "PROD":
    apiUrl = process.env.VUE_APP_API_PROD;
    break;
  case "STAGE":
    apiUrl = process.env.VUE_APP_API_STAGE;
    break;
  default:
    console.log("Not ENV");
}

const axiosIns = axios.create({
  baseURL: apiUrl,
  timeout: 15000,
  headers: {"X-Custom-Header": "foobar"},
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
